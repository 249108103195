h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 43px;
  color: black;
}

p {
  font-family: "Poppins";
}

.outer {
  position: relative;
}

.inner-words {
  border: 0px solid #ddd;
  height: 50px;
  line-height: 50px;
  text-transform: uppercase;
  overflow: hidden;
}

.inner-words span {
  animation: animate 7s ease infinite;
  position: relative;
}

@keyframes animate {
  0% {
    top: 0;
    opacity: 1;
  }
  25% {
    top: -50px;
    opacity: 1;
  }
  50% {
    top: -100px;
    opacity: 1;
  }
  75% {
    top: -150px;
    opacity: 1;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

.wrap {
  background: linear-gradient(
    93.25deg,
    rgba(255, 147, 86, 0.62) 2.15%,
    #8e3706 29.58%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.gradient-text {
  position: relative;

  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 20px;
  color: transparent;
  display: inline-block;
  overflow: hidden;
  background: linear-gradient(
    90deg,
    #000000 0%,
    #000000 20%,
    #1c1b1b 30%,
    #fdba00 40%,
    #764700 55%,
    #fdba00 65%,
    #2d2d2d 75%,
    #171717 80%,
    #000000 100%
  );
  background-size: 300% 100%;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: gradient-flow 10s linear infinite;
}

@keyframes gradient-flow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media screen and (max-width: 991px) {
  .gradient-text {
    text-align: center;

  }
}


