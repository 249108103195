@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  scrollbar-width: thin;
  scrollbar-color: #e4e4e4 #1f2937;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #cccccc;
}

::-webkit-scrollbar-thumb {
  background-color: #80858e;
  border-radius: 10px;
  border: 1px solid #979797;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #9ca3af;
}
